@include mobile-menu-button;

header {
    position: fixed;
    top: $border-stage;
    z-index: 200;
    max-width: 1920px;
    width: 100%;
    height: $header-height-desktop;
    background-color: transparent;
    padding: $header-space 0;
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-down(sm) {
        background: $color-white;
        height: 65px;
        top: 0;
    }

    &.animated {
        transition: top 0.3s ease-in-out;
    }

    &.nav-up {
        top: -$header-height-desktop;
        &.open {
            top: 0;
        }
    }
    img.logo-wrapper__logo {
        position: relative;
        max-height: 100%;
        width: auto;
        @include media-breakpoint-up(sm) {
            top: 50%;
            transform: translate(0, -50%);
        }
        &--white {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        &--dark {
            display: none;
            @include media-breakpoint-down(sm) {
                display: inline-block;
            }
        }

    }
    .logo-wrapper {
        height: ($header-height-desktop - ($header-space * 2));
        a{
            color: transparent;
        }
        &__logo {

        }
    }
    &.top-navigation {
        height: $extended-header-height-desktop;
        @include media-breakpoint-down(sm) {
            height: $extended-header-height-mobile;
        }
        &.nav-up {
            top: -$extended-header-height-desktop;
            @include media-breakpoint-down(sm) {
                top: -$extended-header-height-mobile;

            }
            &.open {
                top: 0;
            }
        }
        .logo-wrapper {
            height: ($extended-header-height-desktop - ($header-space * 2));
            @include media-breakpoint-down(sm) {
                height: ($extended-header-height-mobile - ($header-space * 2));

            }
            a:first-child {
                @include media-breakpoint-up(sm) {
                    margin-right: 35px;
                }
            }
        }
        .main-navigation {
            margin-top: $top-nav-space;

        }
        .language-switch {
            display: inline-block;
            position: relative;
            color: $color-white;
            right: 90px;
            top: -42px;
            float: right;
            @include media-breakpoint-down(lg) {
                display: none;
            }

            ul {
                list-style: none;
                display: inline-block;

                li {
                    display: inline-block;
                    + li {
                        border-left: 1px solid $color-white;
                        margin-left: 5px;
                        padding-left: 8px;
                    }
                    a {
                        color: $color-white;
                        text-decoration: none;
                        padding-bottom: 4px;
                        &:hover {
                            color: $color-orange;
                            border-bottom: 1px solid $color-orange;
                        }
                    }
                    span, span:active {
                        color: $color-orange;
                        font-weight: $font-weight-semibold;
                    }
                }
            }
        }
    }
    .top-navigation-wrapper {
        height: $top-nav-height;
        text-align: right;

        .search-bar {
            display: inline-block;
            margin-right: 10px;
        }

        .top-navigation {
            display: inline-block;
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
            margin: 0 10px 0 0;

            &__list {

            }
            &__item {

            }
        }
        .language-switch {
            display: inline-block;
            position: relative;

            ul {
                list-style: none;
                display: inline-block;

                li {
                    display: inline-block;
                    + li {
                        border-left: 1px solid $color-white;
                        margin-left: 5px;
                        padding-left: 8px;
                    }
                }
            }
        }
    }
    .main-navigation {
        text-align: right;
        height: ($header-height-desktop - ($header-space * 2));
        margin-right: 160px;
        @include media-breakpoint-down(lg) {
            display: none;
        }
        &__list {
            position: relative;
            top: 50%;
            margin: 0;
            transform: translate(0, -50%);
        }

        &__item {
            text-transform: uppercase;
            margin-right: 40px !important;
            a {
                color: $color-white;
                text-decoration: none;
                padding-bottom: 8px;
                &:hover {
                    border-bottom: 1px solid $color-white;
                }
                &:active {
                    border-bottom: 1px solid $color-white;
                }
            }

            &--sub-navigation {
                display: none;
            }
        }
    }
    .big-container {
        padding: 0 50px;

        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }
    }


    &.sticky {
        top: 0;
        transition: top 0.3s ease-in-out;
        box-shadow: 0 10px 25px rgba(40, 40, 40, 0.1);

        background-color: $color-white;
        max-width: 2200px;

        &.stage-full {
            max-width: 2200px;
            margin: 0 auto;
        }

        .main-navigation {
            a {
                color: $text-color;
            }
        }
        .mobile-menu-button {
            span {
                background-color: $text-color;
            }
        }
        img.logo-wrapper__logo {
            &--white {
                display: none;
            }
            &--dark {
                display: inline-block;
            }
        }
        .language-switch {
            ul {
                li {
                    a {
                        color: $text-color;
                    }
                    +li {
                        border-left: 1px solid $text-color;
                    }
                }
            }

        }
    }
}

.top-navigation {
    margin-bottom: 40px;
}