@import "bricks-global";
@import "bricks/accordion";
@import "bricks/cta-advertising";
@import "bricks/news";
@import "bricks/teaser";
@import "bricks/tour-teaser";


.bg-color {
    &--blue {
        background-color: $color-blue;
        .area-wysiwyg__content {
            color: $color-white;
            text-align: left;
        }
        .brick__header--headline > * {
            color: $color-white !important;
            margin-bottom: 30px;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }

    &--red {
        background-color: $red;
    }

    &--white-grey {
        background: $white-grey;
    }

    &--light-grey {
        background: $grey-light;
    }

    &--grey-skyline {
        @include grey-skyline-background;
    }
}