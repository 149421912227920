$name: '.footer';

footer {
    max-width: $site-wrapper-width;
    margin: 0 auto;
}

#{$name} {
    &__main {
        background-color: $color-blue;
        padding: 75px 0 !important;

        #{$name}__column-headline {
            &-link {
                text-decoration: none;
            }
            font-size: 16px;
            font-weight: 700;
            font-family: $font-family-base;
            line-height: 1.5;
            text-transform: uppercase;
            color: #fff;
        }

        #{$name}__column {
            &-one, &-two, &-three {
                #{$name}__column-links {
                    color: #fff;
                    border-top: 1px solid #fff;
                    padding-top: 15px;
                    #{$name}__column-link {
                        font-size: 15px;
                        color: #fff;
                        padding-left: 14px;
                        text-decoration: none;
                        line-height: 1.4em;
                        position: relative;
                        display: inline-block;
                        &:before {
                            content: "\203A";
                            position: absolute;
                            left: 0;
                            top: 0;
                            color: #fff;
                            font-weight: bold;
                            font-size: 1.3em;
                            line-height: 1em;
                            transition: left ease-in-out 250ms;
                        }
                        &:hover {
                            &:before {
                                left: 3px;
                                transition: left ease-in-out 250ms;
                            }
                        }
                    }
                }
            }
        }
    }
    &__bottom {
        padding: 30px 0 !important;
        background-color: #133264;
        color: #fff;
        font-size: 13px;
        a {
            color: #fff;
            text-decoration: none;
        }
    }
}