section.header-stage {
    padding: 0;

    margin-top: -$extended-header-height-desktop;
    @include media-breakpoint-down(sm) {
        margin-top: 0;
    }

    @include media-breakpoint-down(sm) {
        height: auto;
    }

    &--video + .brick {
        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }
    }

    .stage__image-overlay {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        height: 100%;
        z-index: 0;

        &--blue {
            background-color: rgba($blue, 0.75);
        }

        &--green {
            background-color: rgba($green, 0.75);
        }
    }

    &--full {
        @include media-breakpoint-down(sm) {
            height: 800px;
        }
    }

    .stage-snippet {
        position: relative;

        &--full {
            height: $full-slider-height;
            @include media-breakpoint-down(md) {
                height: $full-lg-slider-height;
            }
            @include media-breakpoint-down(md) {
                height: $full-md-slider-height;
            }
            @include media-breakpoint-down(sm) {
                height: $full-xs-slider-height;
            }

            .stage-snippet__slide-background {
                height: $full-slider-height;
                @include media-breakpoint-down(md) {
                    height: $full-lg-slider-height;
                }
                @include media-breakpoint-down(md) {
                    height: $full-md-slider-height;
                }
                @include media-breakpoint-down(sm) {
                    height: $full-xs-slider-height;
                }
            }
        }

        &--fix {
            position: relative;
            @include media-breakpoint-down(sm) {
                height: 350px;
            }
            height: $fix-slider-height;

            .stage-snippet__slide-background {
                height: $fix-slider-height;
            }

            &__content {
                font-weight: $font-weight-bold;
                text-align: center;
                position: absolute;
                top: $extended-header-height-desktop;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                padding: 0 45px;
                z-index: 1;

                &-mobile {
                    font-weight: $font-weight-bold;
                    text-align: left;
                    position: absolute;
                    top: $extended-header-height-mobile + 70px;
                    left: 70px;
                    transform: translateY(-50%);
                    width: auto;
                    z-index: 1;
                    padding-right: 20px;
                }
            }

            &-headline {
                @include stage-h1;
                color: $white;
                line-height: 1.5;
                text-shadow: 0px 0px 20px black;

                &__mobile {
                    font-size: $font-size-24px;
                    font-weight: $font-weight-light;
                    color: $white;
                    text-transform: uppercase;
                    text-shadow: 0px 0px 20px black;
                }

            }

            &-subHeadline {
                @include stage-subHeadline;
                color: $white;
                margin-bottom: 0;
                font-weight: $font-weight-bold;
                text-transform: none;
                text-shadow: 0px 0px 20px black;

                &__mobile {
                    text-transform: uppercase;
                    font-size: $font-size-36px;
                    color: $white;
                    text-shadow: 0px 0px 20px black;
                    hyphens: auto;
                    -moz-hyphens: auto;
                    -ms-hyphens: auto;
                }
            }
        }

        &__slider {

        }

        &__slide {
            position: relative;
            height: 100%;
            outline: none;
            //z-index: -1;
            &-background {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                object-fit: cover;
            }

            &-content {
                position: relative;
                padding: 40px;

                &-subHeadline {
                    font-size: 35px;
                    color: $primary;
                }

                &-headline {
                    font-size: 45px;
                    color: $primary;

                }

                .stage-snippet__slide-buttons {
                    .cta-button-item {
                        margin: 0 10px;
                        text-align: center;
                    }
                }
            }
        }

        &__video {
            position: relative;
            padding-top: 36.38%;
            //pointer-events: none;
            @include media-breakpoint-down(md) {
                padding-top: 36.38%;
            }

            video {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                height: 100%;

            }
        }

        &.stage-media--video {
            height: auto;
        }
    }


    .sub-stage {
        position: relative;

        &__map {
            overflow: hidden;
            /*height: 500px;*/
            @include media-breakpoint-down(lg) {
                height: auto;
            }
            @include media-breakpoint-down(sm) {
                height: 330px;
            }

            &-mobile {
                display: none;

                img {
                    width: 100%;
                }
            }
        }

        &-headline {
            @include stage-h1;
            line-height: 1.2;
            text-transform: uppercase;
            @include media-breakpoint-down(md) {

            }
        }

        &-button {
            .cta-button-item {
                a {
                    background-color: $blue-light !important;

                    &:hover {
                        background-color: $red !important;
                    }
                }

                margin-top: 40px;
                margin-bottom: 70px;
                @include media-breakpoint-down(lg) {
                    margin-bottom: 50px;
                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                    margin-top: 20px;
                }
            }
        }

        .usp-element {
            &-wrapper {
                margin-top: 40px;
                @include media-breakpoint-down(md) {
                    margin-top: 20px;
                }
            }

            &__icon {
                display: inline-block;
                color: $blue-light;
                width: 25px;

            }

            &__text {
                display: inline-block;
                color: $text-color;
                white-space: nowrap;

            }
        }

        .overlay-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            @include media-breakpoint-down(sm) {
                .container {
                    max-width: 100%;
                }
            }

            .overlay-icons {
                font-size: 125px;
                color: $grey-light;
                position: absolute;
                bottom: -50px;
                left: -300px;

                .overlay-bus {
                    display: inline-block;

                    img {
                        width: 130px;
                    }
                }

                .overlay-busstop {
                    display: inline-block;
                    margin-bottom: 28px;
                    height: 160px;
                }
            }
        }

        .overlay-box {
            position: relative;
            background: $white;
            height: 510px;
            padding: 37px 50px 0px 110px;
            @include media-breakpoint-down(lg) {
                padding: 20px 50px 0px 50px;
                height: 470px;
            }
            @include media-breakpoint-down(md) {
                padding: 20px 30px 0px 30px;
                height: 360px;
            }
            @include media-breakpoint-down(sm) {
                min-height: auto;
            }
        }
    }

    .header-stage__callout {
        position: relative;

        &-full {

            img {
                position: absolute;
                left: 300px;
                top: 400px;
                @include media-breakpoint-down(lg) {
                    left: 200px;
                }
                @include media-breakpoint-down(md) {
                    left: 100px;
                    top: 200px;
                }
                @include media-breakpoint-down(sm) {
                    top: 70px;
                    left: 70px;
                }
            }
        }

        &-full2 {
            position: relative;

            .callout2-wrapper {
                position: relative;
            }

            img {
                position: absolute;
                top: -200px;
                z-index: 1;
                @include media-breakpoint-down(md) {
                    top: -170px;
                }
                @include media-breakpoint-down(sm) {
                    top: -50px;
                    right: 0;
                }
            }
        }

        &-full3 {
            position: relative;

            img {
                position: absolute;
                right: 190px;
                top: 400px;
                z-index: 1;
                @media (max-width: 1400px) {
                    right: -60px;
                }
                @include media-breakpoint-down(lg) {
                    top: 360px;
                    right: -50px;
                }
                @include media-breakpoint-down(md) {
                    top: 250px;
                }
                @include media-breakpoint-down(sm) {
                    top: 260px;
                }
                @include media-breakpoint-down(sm) {
                    display: none;
                    /*top: 60px;
                    left: -10px;
                    @media (max-width: 480px) {
                      //top: 80px;
                    }
                    @media (max-width: 420px) {
                      //top: 80px;
                    }*/
                }
            }
        }

        &-fix {
            img {
                position: absolute;
                right: 60px;
                top: 500px;
                z-index: 1;
                @include media-breakpoint-down(lg) {
                    top: 550px;
                }
                @include media-breakpoint-down(md) {
                    top: 300px;
                }
                @include media-breakpoint-down(sm) {
                    right: auto;
                    left: -40px;
                    top: 300px;
                    @media (max-width: 400px) {
                        left: -35px;
                    }
                }
            }

            &__mobile {
                img {
                    position: absolute;
                    right: 0px;
                    top: 210px;
                    z-index: 2;

                }
            }
        }
    }

    .header-stage__bus {
        img {
            position: absolute;
            bottom: -50px;
            left: 100px;
            z-index: 1;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

}

/*
#siteWrapper.top-navigation {
  section.header-stage {
    .stage-snippet--full {
      height: calc(100vh - #{$extended-header-height-desktop});
      .stage-snippet__slide-background {
        height: calc(100vh - #{$extended-header-height-desktop});
      }
    }
  }
}*/