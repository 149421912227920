@mixin mobile-menu-button () {

  .mobile-menu-button {
    width: $mobile-menu-button-width;
    height: $mobile-menu-button-height;
    position: fixed;
    top: 42px;
    right: $padding-header;
    margin: (52px - $mobile-menu-button-height) / 2 0;
    margin-left: auto;

    transform: rotate(0deg);
    @include transition(.5s ease-in-out);
    cursor: pointer;

    span {
      border-radius: $mobile-menu-button-height / 3.5;
      left: 0;
      display: block;
      position: absolute;
      height: $mobile-menu-button-height / 3.5;
      width: 100%;
      opacity: 1;

      @include transition(.25s ease-in-out);
      background-color: $color-white;

      &:nth-child(1) {
        top: 0;
        width: 100%;
      }

      &:nth-child(2) {
        top: ($mobile-menu-button-height / 5) * 3;
        width: ($mobile-menu-button-width / 3) * 2;
      }

    }

    @include media-breakpoint-down(sm) {
      right: auto;
      left: 15px;
      top: 25px;
      margin: 0;
      span {
        background-color: $text-color;
      }
    }
  }

  body.mobile-menu-open {
    .mobile-menu-button {
      span {
        &:nth-child(1) {
          width: ($mobile-menu-button-width / 2);
          top: 5px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          width: ($mobile-menu-button-width / 2);
          top: 5px;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }
    }
  }
}