@import "../../../dt/sass/layout/tour-details";
@import "../../../dt/sass/layout/header-stage";

.tour-details {
    &__subContent {
        &-link {
            a {
                background-color: $color-secondary;
                &:hover {
                    background-color: $color-primary;
                    @include transition(all ease-in-out 300ms);
                }
            }
        }
    }
}