/**
 * Bootstrap Overrides
 */
$green: #2ed03c;
$lightBlue: #4AB8E6;
$blue: #345260;
$gray-dark: #5D5D5D;
$gray: #D9D9D9;
$gray-light: #F5F5F5;
$white: #fff;
$black: #13262D;
//$red: #FF7276;
$yellow: #ffe000;
$grey-3: #575756;

$white-grey: #F1F1F1;
$grey-light: #E5E5E5;
$grey: #DADADA;
$grey-dark: #494949;
$grey-darker: #3C3C3B;
$grey-blue: #B2C3D5;
$orange: #F9B21F;
$ice-blue: #E0E6EE;
$mint: #17BDA5;
$body-color: $grey-darker;
$blue-light: #6787AC;

$kitzSkiXpressColor: #CC151A;
$alpenPlusSkiExpressColor: #0683b7;

/* === Bootstrap Global === */
$enable-rounded: true;
$enable-responsive-font-sizes: true;

$site-wrapper-width: 2200px;

/* === Header / Navigation === */
$header-height-desktop: 80px;
$extended-header-height-desktop: 140px;
$extended-header-height-mobile: 65px;
$header-space: 10px;
$top-nav-height: 40px;
$top-nav-space: 30px;

$padding-header: 80px;
$border-stage: 30px;
$border-stage-fix: 30px 30px 0;

$font-family-base:  'Montserrat', sans-serif;
$fontfamily-fontawesome-brands: 'Font Awesome 5 Brands';

/* === Colors === */
$color-white: #FFFFFF;
$color-black: #000000;
$red: #D30032;
$color-white-grey: #F1F1F1;
$color-grey-light: #E5E5E5;
$color-grey: #DADADA;
$color-grey-dark: #494949;
$color-grey-darker: #3C3C3B;
$color-grey-blue: #B2C3D5;
$color-orange: #F9B21F;
$color-ice-blue: #E0E6EE;
$color-blue: #003975;
$color-blue-light: #6787AC;
$color-blue-darker: #133264;
$color-green: #4CB827;
$color-mint: #17BDA5;
$text-color: $color-grey-darker;

$color-primary: $color-blue;
$color-secondary: $color-orange;


/* === Bricks === */
$brick-padding: 75px;
$brick-in-brick-padding: 20px;

$brick-header-bottom-space: 30px;
$brick-footer-top-space: 30px;

/* === Stage === */
$fix-slider-height: 475px;
$full-slider-height: 800px;
$full-lg-slider-height: 650px;
$full-md-slider-height: 500px;
$full-xs-slider-height: 250px;
$stage-text-color: $color-white;
$stage-navigation-color: $color-white;
$stage-navigation-active-color: $color-primary;

/* === font sizes === */
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-24px: 1.5rem;
$font-size-32px: 2rem;
$font-size-36px: 2.25rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;


$screen-xs-max: 575px;
$screen-sm-min: $screen-xs-max + 1;
$screen-sm-max: 767px;
$screen-md-min: $screen-sm-max + 1;
$screen-md-max: 991px;
$screen-lg-min: $screen-md-max + 1;

/* === Sizes === */
$mobile-menu-button-width: 45px;
$mobile-menu-button-height: 15px;