.stage-snippet--fix {
    height: $full-slider-height !important;
    @include media-breakpoint-down(md) {
        height: $full-lg-slider-height !important;
    }
    @include media-breakpoint-down(md) {
        height: $full-md-slider-height !important;
    }
    @include media-breakpoint-down(sm) {
        height: 350px !important;
    }

    .stage-snippet__slide-background {
        height: $full-slider-height !important;
        @include media-breakpoint-down(md) {
            height: $full-lg-slider-height !important;
        }
        @include media-breakpoint-down(md) {
            height: $full-md-slider-height !important;
        }
        @include media-breakpoint-down(sm) {
            height: 350px !important;
        }
    }

    &__content {
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%) !important;
    }

    &-headline__mobile {
        text-shadow: 0px 0px 20px #000 !important;
        font-weight: 700 !important;
    }
    &-subHeadline__mobile {
        font-size: 1.2rem !important;
    }
}

.tour-details {
    position: relative;
    &__topContent {
        padding-top: $brick-padding;
        @include media-breakpoint-down(md) {
            padding-top: 48px;
        }
        &-headline {
            margin-bottom: 40px;
            font-weight: $font-weight-bold;
            font-size: $font-size-32px;
            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }

        &-content {
            &-left {
                ul {
                    margin-left: -20px;

                    li {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }

    &__editions {
        @include media-breakpoint-up(md) {
            padding: $brick-padding;
        }
        @include media-breakpoint-down(md) {
            padding: $brick-padding 0;
            .container {
                padding: 0;
            }
        }

        .divider {
            height: 4px;
            width: 100%;
            background-color: $blue-light;
            &.bg-blue-light {
                .divider {
                    background-color: $white;
                }
            }
        }

        .tour-details__edition {
            background-color: $white-grey;
            padding: 0;
            @include media-breakpoint-up(md) {
                width: 50%;
                &.first {
                    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.20);
                }
                &.second {
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.20);
                }
                &.third {
                    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.20);
                }
            }

            &.first {
                position: relative;
                .premium-crown {
                    position: absolute;
                    top: -42.5px;
                    right: 40px;
                    @include media-breakpoint-down(md) {
                        top: -30px;
                        right: 30px;
                        img { height: 60px; }
                    }
                }
            }

            .btn.btn-primary {
                margin-top: 30px;
            }

            .quick-facts-wrapper {
                padding-bottom: 12px;
                .quick-fact {
                    gap: 12px;
                    i {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            &.bg-blue-light {
                background-color: $blue-light;
                * {
                    color: $white;
                }
                a {
                    color: $white;
                    font-weight: bold;
                }
                .tour-details__edition {
                    &-prices {
                        &:before {
                            background-color: $white;
                        }
                    }
                }
                .btn.btn-primary {
                    &:hover {
                        background-color: $white;
                        color: $red;
                    }
                }
                h3 {
                    color: $white;
                }
            }

            &-facts, &-prices {
                h2 {
                  font-size: 32px;
                }
                h3 {
                    font-size: 28px;
                    color: $grey-darker;
                    text-transform: none;
                    padding-bottom: 30px;
                }
                @include media-breakpoint-up(md) {
                    padding: 44px 30px;
                }
                @include media-breakpoint-down(md) {
                    padding: 24px 16px;
                }
            }

            &-price-infos {
                padding: 0 30px 44px 30px;
            }

            &-prices {
                position: relative;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    height: 4px;
                    width: 100%;
                    background-color: $blue-light;
                }
                .prices-wrapper {
                    gap: 12px;
                    i {
                        display: flex;
                        align-items: center;
                    }
                    label {
                        padding-left: 16px;
                        &.child {
                            padding-left: 20px;
                        }
                        @include media-breakpoint-down(md) {
                            max-width: 75%;
                        }
                    }
                    .price span {
                        @include media-breakpoint-down(md) {
                            align-self: center;
                        }
                    }
                }
            }
        }
    }

    &__tour-description-area {
        padding-bottom: 74px;
        @include media-breakpoint-down(md) {
            background-color: $grey-light;
        }
        .tour-details__tour-description-title {
            padding: $brick-padding 0 20px 0;
        }
        h2, h3 {
            font-size: 32px;
            text-transform: none;
            color: $grey-darker;
        }
        h2 {
            font-weight: 700;
        }
        h2:not(:first-child) {
            padding-top: 48px;
        }
        h3 {
            font-weight: 600;
            margin: 0;
            padding-top: 30px;
        }
        ul {
            padding: 30px 0 0 30px;
            margin: 0;
        }
        p {
            padding-top: 30px;
        }
        p:not(:last-of-type) {
            margin: 0;
        }

        .tour-details__tour-description-content {
            &.collapse {
                &.disable-collapse {
                    @include media-breakpoint-up(sm) {
                        display: block !important;
                        height: auto !important;
                        visibility: visible;
                        padding-top: 0;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                padding-top: $brick-padding;
            }
        }

        .tour-details__tour-description-content-icon {
            position: relative;
            font-size: 2rem;
            color: $grey-darker;
            cursor: pointer;

            &::before {
                content: '';
                display: block;
                background-color: $grey-darker;
                height: 1px;
                width: calc(50% - 10px);
                position: absolute;
                top: 17px;
                left: 0;
            }

            &::after {
                content: '';
                display: block;
                background-color: $grey-darker;
                height: 1px;
                width: calc(50% - 10px);
                position: absolute;
                top: 17px;
                right: 0;
            }

        }
    }

    &__notes {
        background-color: $blue-light;
        padding: $brick-padding 0;
        color: $white;
        a {
            color: $white;
            font-weight: bold;
        }

        &-title {
            color: $white;
            margin-bottom: 40px;
            text-transform: none;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        &-line {

            &[aria-expanded="true"] {
                background: $white;
                height: 1px;
                width: calc(100%);
                position: absolute;
                top: -15px;
                left: 0;
            }
        }

        &-content {
            position: relative;

            &-left {
                ul {
                    margin-left: -20px;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }

                    li {
                        padding-bottom: 20px;
                    }
                }
            }

            &-right {
                ul {
                    margin-left: -20px;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }

                    li {
                        padding-bottom: 20px;

                        &:last-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }

            &.collapse {
                //padding-top: 50px;
                &.disable-collapse {
                    @include media-breakpoint-up(sm) {
                        display: block !important;
                        height: auto !important;
                        visibility: visible;
                        padding-top: 0;
                    }
                }

            }

            &-icon {
                position: relative;
                font-size: 2rem;
                color: $white;
                cursor: pointer;

                &::before {
                    content: '';
                    display: block;
                    background-color: $white;
                    height: 1px;
                    width: calc(50% - 10px);
                    position: absolute;
                    top: 17px;
                    left: 0;
                }

                &::after {
                    content: '';
                    display: block;
                    background-color: $white;
                    height: 1px;
                    width: calc(50% - 10px);
                    position: absolute;
                    top: 17px;
                    right: 0;
                }

            }
        }

    }

    &__imageContent {
        padding-bottom: $brick-padding;
        padding-top: $brick-padding;
        @include media-breakpoint-down(sm) {
            padding-top: $brick-padding;
        }

        &-image2 {
            @include media-breakpoint-down(sm) {
                float: left;
            }
        }

        &-image3 {
            @include media-breakpoint-down(sm) {
                float: right;
            }
        }
    }

    &__map {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__subContent {
        padding: $brick-padding 0;
        display: block !important;

        &-headline {
            //color: $primary;
            @include stage-h1;

        }

        &-text {
            padding: 20px 0;
        }

        &-link {
            a {
                background-color: $primary;

                &:hover {
                    background-color: $blue-light;
                }
            }
        }

        &-gallery {
            padding-top: 80px;
        }
    }

    &__information {
        padding: $brick-padding 0;
        background-color: $white-grey;
        display: block !important;

        &-bus {
            img {
                position: absolute;
                top: -130px;
                right: -160px;
                @media (max-width: 1600px) {
                    right: -10px;
                }
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &-headline {
            text-transform: uppercase;
            color: $text-color;
            font-weight: $font-weight-bold;
        }
    }
}