.b-teaser {

    &.brick {
        padding-top: 0;
    }

    .teaser-items {

        &__row {
            @include media-breakpoint-down(xs) {
                padding: 0 5px;

                .teaser-item {
                    padding: 0 15px;
                    margin-bottom: 30px;
                }
            }

            .slick-slide {
                &:not(.slick-center):not(.slick-active) {
                    transform: scale(0.9);
                    @include transition(all 300ms);
                    opacity: 0.7;
                }

                &.slick-center.slick-active {
                    transform: scale(1);
                    @include transition(all 300ms);
                    opacity: 1;
                }
            }
        }
    }

    &__items {
        padding: 0;

        .teaser-object {
            height: 100%;
        }


        &__slider {
            position: relative;
            padding: 0 0;
            padding-bottom: 30px;

            .swiper {
                .swiper-wrapper {
                    .swiper-slide {
                        .teaser-tour__slider {
                            @include media-breakpoint-down(sm) {
                                max-width: 300px;
                                padding: 10px 15px;
                                background-color: $white-grey;
                            }
                        }
                    }
                }
            }
        }

        .swiper-nav-prev, .swiper-nav-next {
            position: absolute;
            cursor: pointer;
            z-index: 10;
            width: 18px;
            height: 18px;
            outline: none;
            bottom: 0;
        }
        .swiper-nav-prev {
            background: url("../../img/arrow-left_m.svg") no-repeat center;
        }

        .swiper-nav-next {
            background: url("../../img/arrow-right_m.svg") no-repeat center;
        }

        .swiper-nav-prev {
            left: 15px;
        }

        .swiper-nav-next {
            right: 15px;
        }


        &--masonry {
            margin-left: -15px;
            margin-right: -15px;

            .teaser-item {
                display: inline-block;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 0;
                margin-bottom: 15px;
                float: left;

                &--twoColumn {
                    width: 50%;
                }

                &--threeColumn {
                    width: 33.333333%;
                }
            }
        }

        .pagination-wrapper {
            @include media-breakpoint-down(sm) {
                margin-top: 40px;
            }
            .swiper-pagination-bullets {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                padding: 0;
                margin: 0;

                .swiper-pagination-bullet {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: $color-grey;
                    border-radius: 12px;
                    font-size: 1px;
                    text-indent: -9999px;
                    margin: 0 3px;
                    border: none;
                    padding: 0;
                    cursor: pointer;
                    outline: none;

                    &-active {
                        background: $color-orange;
                    }
                }
            }
        }

    }

    &__advertising-mobile {
        @include media-breakpoint-down(xs) {
            padding-top: 17px;
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}