.b-news {
    font-family: $font-family-base;

    &__headline {
        color: $grey-3;
    }
    &__item {
        color: $grey-3 !important;
        &__body {
            padding: 32px;

            .copy-s {
                font-size: 14px;
                font-weight: 300;
            }
            &__link {
                a {
                    color: $red;

                }
            }
            &__title {
                font-size: 28px;
            }
        }
    }
    &__button {
        color: white;
        display: block;
        font-weight: bold;
        padding: 10px 25px;
        margin: 30px auto 80px;
        text-transform: uppercase;
    }
}

section.light-grey {
    background: $grey-light;

    .news-detail {
        &__link-top {
            text-transform: uppercase;
            margin: $brick-padding 0;
        }

        &__content {
            &-headline {
                text-transform: uppercase;
            }
            .h4 {
                text-transform: none;
                font-weight: bold;
            }
            &-images {
                margin: $brick-padding 0;
            }
        }

        &__further-articles {
            h3 {
                text-transform: none;
                color: $grey-dark;
                text-align: center;
            }
            &__article {
                padding: 1.5rem 0;
                border-top: 2px solid $grey;
                &:last-child {
                    border-bottom: 2px solid $grey;
                }
                h3 {
                    text-transform: none;
                    font-weight: bold;
                    color: $grey-darker;
                    text-align: center;
                }
                &__date {
                    color: $grey-dark;
                    margin-bottom: 0.7rem;
                }
                &__headline {
                    font-weight: bold;
                    color: $grey-darker;
                    margin-bottom: 0.7rem;
                }
                &__teaserTextShort {
                    display: flex;
                    margin-bottom: 0.7rem;
                    gap: 5px;
                }
                &__link {
                    text-transform: uppercase;
                }
            }
        }

        &__link-bottom {
            padding: 1.5rem 0;
            border-top: 2px solid $grey;
            text-align: center;
        }
    }
}