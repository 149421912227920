.b-accordion{
    &__headline {
        text-align: center !important;
        color: $color-primary;
    }
}

.accordion{
    &__headline {
        position: relative;
        font-weight: bold;
        margin-bottom: 1rem;
        display: block;
        border-top: 1px solid rgba($color-primary, 0.2);
        padding-top: 1rem;
        line-height: 2.5rem;
        color: $color-grey-dark;
        cursor: pointer;
        font-size: $font-size-18px;

        &:hover {
            text-decoration: none;
        }
        &.collapsed{
            color: $color-primary;

            .accordion__icon {
                &--down {
                    display: none;
                }
                &--up {
                    display: inline-block;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding-right: 40px;
            line-height: 1.5;
        }

        @include media-breakpoint-down(xs) {
            font-size: $font-size-18px * 0.9;
            line-height: 1.3;
        }
    }
    &__icon {
        font-size: 1.5rem;
        margin-top: 5px;

        @include media-breakpoint-down(sm) {
            position: absolute;
            right: 0;
            top: calc(50% + 0.5rem); //  (because __headline has margin-bottom 1rem)
            transform: translateY(-50%);
            margin-top: 0;
            font-size: 1.2rem;
        }

        &--down {
            display: inline-block;
        }

        &--up {
            display: none;
        }
    }
    &__content {
        p {
            margin-bottom: 1rem;
        }
    }

    &__item {
        &:first-child .accordion__headline {
            border-top: 0;
        }
        &:last-child {
            border-bottom: 1px solid rgba($color-primary, 0.2);
        }
    }

    &__block {
        &:not(:first-child) {
            margin-top: 30px;
        }

        &-headline {
            font-size: $font-size-24px;
            color: $color-grey-dark;
            text-align: center;
            font-weight: $font-weight-semibold;
            padding-bottom: 1rem;
            border-bottom: 1px solid $color-grey-dark;
        }
    }
}

.float-right {
    float: right !important;
}