body {
    font-family: $font-family-base;
}

#siteWrapper {
    padding-top: $header-height-desktop;
    max-width: $site-wrapper-width;
    margin: 0 auto;
}

.btn {
    &-primary {
        border-radius: 0;
        background-color: $color-orange;
        text-transform: uppercase;
        color: $color-white;
        border: none;
        @include transition(all ease-in-out 300ms);
        font-weight: $font-weight-bold;
        padding: 17px 25px;

        &:hover {
            background-color: $color-primary;
            @include transition(all ease-in-out 300ms);
        }
    }
    &-blue {
        border-radius: 0;
        background-color: $color-blue;
        text-transform: uppercase;
        color: $color-white;
        border: none;
        @include transition(all ease-in-out 300ms);
        font-weight: $font-weight-bold;
        padding: 15px 25px;

        &:hover {
            background-color: $color-orange;
            @include transition(all ease-in-out 300ms);
        }
    }

    &-red, &-secondary {
        border-radius: 0;
        background-color: $red;
        text-transform: uppercase;
        color: $color-white;
        @include transition(all ease-in-out 300ms);
        font-weight: $font-weight-bold;
        padding: 15px 25px;
        border: 2px solid $color-white;

        &:hover {
            background-color: $color-white;
            color: $red;
            @include transition(all ease-in-out 300ms);
            border: 2px solid $red;
        }
    }

    &-link {
        font-weight: $font-weight-bold;
        color: $color-primary;
        padding-left: 25px;
        text-decoration: none !important;
        background: url("../../img/long-arrow-alt-right-blue.svg") no-repeat center left;
        background-size: 20px 20px;
        border: none;
        @include transition(all ease-in-out 300ms);

        &:hover {
            color: $color-orange;
            background: url("../../img/long-arrow-alt-right-orange.svg") no-repeat center left;
            background-size: 20px 20px;
            @include transition(all ease-in-out 300ms);
        }


    }

    &.btn-double-arrow {
        position: relative;
        color: white;
        display: block;
        font-weight: bold;
        padding: 10px 25px;
        text-transform: uppercase;
        background-color: $red;
        border: 2px solid $red;

        &:after {
            content: "\f101";
            font-family: 'Font Awesome 6 Pro';
            font-weight:  900;
            display: inline-block;
            padding-left: 15px;
        }

    }
}

.color-white {
    color: $color-white;
}

.color-blue {
    color: $color-blue;
}

.color-grey {
    color: $color-white-grey;
}

.text-color {
    color: $color-grey-dark;
}