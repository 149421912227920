.b-cta-advertising {
    position: relative;

    &__content-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: rgba($red, 0.75);

        &.bg-gray {
            background-color: rgba($gray-light, 0.75);
        }
    }
    &__content-image {
        img {
            margin-top: -$brick-padding;
            position: absolute;
            top: $brick-padding;
            left: 0;
            z-index: 0;
            min-height: 100%;
            max-height: 100%;
        }
    }

    &__content {
        z-index: 2;
        position: relative;

        &__title {
            z-index: 2;
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 450px;
                height: 1px;
                background: $white;
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translateX(-50%);

            }
        }

        &__text {
            z-index: 2;
            margin: 40px 0;
            @include media-breakpoint-down(xs) {
                hyphens: auto;
            }
        }

        &__link {
            z-index: 2;
            margin-top: 30px;
        }
    }

    &__callout {
        z-index: 2;
        .container > div {
            position: relative;

            img {
                position: absolute;

                @include media-breakpoint-up(lg) {
                    top: calc(-100px - #{$brick-padding});
                    right: -30px;
                }
                @include media-breakpoint-between(md, lg) {
                    top: calc(-80px - #{$brick-padding});
                }

                @include media-breakpoint-between(sm, md) {
                    top: calc(-50px - #{$brick-padding});
                }

                @include media-breakpoint-down(xs) {
                    top: calc(-30px - #{$brick-padding});
                    right: -15px;
                }

            }
        }
    }

    &__content-overlay-left {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: $red;

        &.bg-gray {
            background-color: $grey-light;
        }
    }


    &__left {
        position: relative;

        &__content {
            position: relative;
            z-index: 2;

            &__title {
                position: relative;
                color: $white;
                &::after {
                    display: block;
                    width: 450px;
                    height: 1px;
                    background: $white;
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    transform: translateX(-50%);

                }
            }

            &__text {
                color: $white;
                margin: 0;
                @include media-breakpoint-down(xs) {
                    hyphens: auto;
                }
            }
            &__link {
                margin-top: 30px;
            }

            &.bg-gray {
                color: $black;

                .b-cta-advertising__left__content {
                    &__title {
                        color: $black;
                    }
                    &__text {
                        color: $black;
                    }
                }
            }
        }

        &__callout {
            .container > div {
                position: relative;

                img {
                    position: absolute;

                    @include media-breakpoint-up(lg) {
                        top: calc(-100px - #{$brick-padding});
                        right: -30px;
                    }
                    @include media-breakpoint-between(md, lg) {
                        top: calc(-80px - #{$brick-padding});
                    }

                    @include media-breakpoint-between(sm, md) {
                        top: calc(-50px - #{$brick-padding});
                    }

                    @include media-breakpoint-down(xs) {
                        top: calc(-30px - #{$brick-padding});
                        right: -15px;
                    }

                }
            }
        }
    }

}
